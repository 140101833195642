<script>
import "@/views/Landing/style/content.scss";

export default {
  data: () => ({
    phone_number: null,
    name: null,
    organization: null,
    showDefault: true,
    showCallbackForm: false,
    callbackSuccess: false,
    iAgree: true,
    freeTooltip: '<strong>Бесплатно:</strong>' +
        '<p>&nbsp;&nbsp;∘ Публикация заказов на агрегаторе торговли.</p>' +
        '<p>&nbsp;&nbsp;∘ Поиск и переписка с организациями.</p>' +
        '<p>&nbsp;&nbsp;∘ Мессенджер для общения с сотрудниками других организаций.</p>' +
        '<p>&nbsp;&nbsp;∘ Публикация и В2С продажа товаров и услуг в приложении «Обращайся».</p>' +
        '<p>&nbsp;&nbsp;∘ Ведение контента в приложении «Обращайся».</p>',
    businessTooltip: '<strong>Бизнес подписка на 1 год 52 000:</strong>' +
        '<p>&nbsp;&nbsp;∘ Публикация предложений на поставку товаров и услуг на витрине.</p>' +
        '<p>&nbsp;&nbsp;∘ Доступ к заявкам и заказам поставщиков, с прямыми контактами.</p>' +
        '<p>&nbsp;&nbsp;∘ Публикация заказов на агрегаторе торговли.</p>' +
        '<p>&nbsp;&nbsp;∘ Поиск и переписка с организациями.</p>' +
        '<p>&nbsp;&nbsp;∘ Мессенджер для общения с сотрудниками других организаций.</p>' +
        '<p>&nbsp;&nbsp;∘ Публикация и В2С продажа товаров и услуг в приложении «Обращайся».</p>' +
        '<p>&nbsp;&nbsp;∘ Ведение контента в приложении «Обращайся».</p>',
    premiumTooltip: '<strong>Премиум подписка на 1 год 120 000:</strong>' +
        '<p>&nbsp;&nbsp;∘ Закрепление предложений на первых местах в агрегаторе торговли.</p>' +
        '<p>&nbsp;&nbsp;∘ Показы товаров , услуг и предложений для всех регионов.</p>' +
        '<p>&nbsp;&nbsp;∘ Рекламный баннер в кабинетах со ссылкой на мессенджер или любые другие ресурсы.</p>' +
        '<p>&nbsp;&nbsp;∘ Публикация предложений на поставку товаров и услуг на витрине.</p>' +
        '<p>&nbsp;&nbsp;∘ Доступ к заявкам и заказам поставщиков, с прямыми контактами.</p>' +
        '<p>&nbsp;&nbsp;∘ Публикация заказов на агрегаторе торговли.</p>' +
        '<p>&nbsp;&nbsp;∘ Поиск и переписка с организациями.</p>' +
        '<p>&nbsp;&nbsp;∘ Мессенджер для общения с сотрудниками других организаций.</p>' +
        '<p>&nbsp;&nbsp;∘ Публикация и В2С продажа товаров и услуг в приложении «Обращайся».</p>' +
        '<p>&nbsp;&nbsp;∘ Ведение контента в приложении «Обращайся».</p>',
    paymentsMethods: '<strong>Способы оплаты:</strong>' +
        '<p>Принимаем к оплате карты платёжных систем VISA, MasterCard, МИР.</p>' +
        '<p>Чтобы оплатить товар банковской картой, выберите этот способ оплаты при оформлении заказа. Далее перейдите на платёжную страницу банка и введите данные вашей банковской карты:</p>' +
        '<p>&nbsp;&nbsp;&nbsp;∘ номер;</p> ' +
        '<p>&nbsp;&nbsp;&nbsp;∘ срок действия;</p> ' +
        '<p>&nbsp;&nbsp;&nbsp;∘ трёхзначный CVC2/CVV2 код (указан на оборотной стороне).</p>' +
        '<p>Если ваша карта подключена к услуге 3D-Secure, вы будете автоматически переадресованы на страницу банка, который выпустил карту, для аутентификации.</p> ' +
        '<p>Интернет-платежи обрабатываются через безопасный шлюз банка согласно международному сертификату безопасности PCI DSS. Информация шифруется по технологии SSL и недоступна посторонним лицам».</p>',
    conditionsRefusal: '<strong>Условия отказа:</strong>' +
        '<p>&nbsp;&nbsp;&nbsp;∘  По закону «О защите прав потребителей» вы можете расторгнуть договор об оказании услуги в любое время. При этом часть услуг, которые уже были оказаны, нужно оплатить.</p>' +
        '<p>&nbsp;&nbsp;&nbsp;∘  При оплате банковской картой деньги вернутся на ту карту, с которой был сделан платёж. Срок возврата — от 1 до 30 рабочих дней.</p>',
  }),
  emits: [
    'sendQuestion',
    'changeState',
  ],
  name: "common-content",
  computed: {
    getScrollAnimate() {
      return window.innerWidth <= 1152 ? {} : { enterClass: 'fadein', leaveClass: 'fadeout' };
    },
  },
  methods: {
    send(event) {
      this.callbackSuccess = true;

      const target = event.currentTarget;
      const parent = target.closest('.center');
      const form = parent.querySelector('.callback-form');

      form.classList.remove('fadeOpacity');
      form.classList.add('fadeOpacityNone');

      this.$emit('sendQuestion', {phone: this.phone_number, name: this.name, organization: this.organization});

      setTimeout(() => {
        this.showCallbackForm = false;

        const finish = parent.querySelector('.finish-form');

        finish.classList.add('fadeOpacity');
      }, 500);
    },
    openForm(event) {
      this.showCallbackForm = true;

      const target = event.currentTarget;
      const parent = target.closest('.center');
      const common = parent.querySelectorAll('.common-form');

      common.forEach(el => {
        el.classList.add('fadeOpacityNone');
      });

      setTimeout(() => {
        this.showDefault = false;

        const form = parent.querySelector('.callback-form');

        form.classList.add('fadeOpacity');
        form.scrollIntoView(false);
      }, 500);
    },
  },
}
</script>

<template>
  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <h2 class="header-title">Цифровой ассистент для государственных и муниципальных служащих</h2>
        <!-- <h2 class="header-title"><span class="red-text">Больше</span> заказов</h2> -->

        <span>Сервис быстрой подготовки ответов на обращения граждан и организаций на основе искусственного интеллекта</span>

        <p>Повышение компетенции и эффективности сотрудников, работающих с обратной связью</p>

        <div class="group">
          <button @click="$emit('changeState', 'business')" class="space">
            Бизнес
            <img src="@/views/Landing/images/icon/arrow_forward_ios.svg" alt="" />
          </button>
          <!--        <button @click="$emit('changeState', 'trading')" class="space">-->
          <!--          Бизнес с юридическими лицами-->
          <!--          <img src="@/views/Landing/images/icon/arrow_forward_ios.svg" alt="" />-->
          <!--        </button>-->
          <button @click="$emit('changeState', 'department')" class="space">
            Муниципалитет
            <img src="@/views/Landing/images/icon/arrow_forward_ios.svg" alt="" />
          </button>
        </div>
      </div>

      <img src="@/views/Landing/images/common/common-1-img.svg" alt=""/>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section column">
      <div class="column">
        <h2>Экономия времени и ресурсов учреждения</h2>

        <div class="description-container">
          <span class="description-item">
            <span class="bold">Готовые ответы</span> на любые обращения 
          </span>

          <span class="description-item">
            <span class="bold">Оперативное</span> согласование с руководством
          </span>

            <span class="description-item">
              Решение рабочих вопросов в единой системе
          </span>    
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper half-bg" v-animateonscroll="getScrollAnimate">
    <div class="half" />
    <div class="section" style="z-index: 2">
      <div class="column" style="z-index: 2">
        <div class="description-container" style="z-index: 2">
          <h2>Инструменты и функциональность</h2>

          <span class="description-item">
          <span class="bold">WEB -</span> личный кабинет для  В2В, В2G, G2G
        </span>

          <span class="description-item">
          <span class="bold">Мобильное приложение -</span> медиаплатформа В2С
        </span>

          <span class="description-item">
          Система взаимодействия людей, бизнеса и власти
        </span>
        </div>
      </div>

      <img src="@/views/Landing/images/tel.svg" alt="" style="z-index: 2" />
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <h2>Зарегистрируй организацию для начала работы</h2>

        <span class="description-item normal">
         Добавьте сотрудников и ответственных лиц для удобства связи с Вами
      </span>
      </div>

      <img src="@/views/Landing/images/Group_121.svg" alt=""/>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <div class="center">
          <h2>Регистрация в системе</h2>

          <span class="normal">происходит с действительными реквизитами организации</span>

          <div class="schema">
            <div class="schema-item">
              <span class="text">Оставьте заявку на сайте, пройдите проверку</span>

              <span class="number line">1</span>
            </div>

            <div class="schema-item reverse">
              <span class="text">Получите логин и пароль от личного кабинета</span>

              <span class="number line">2</span>
            </div>

            <div class="schema-item">
              <span class="text">Начнинайте пользоваться сервисом! Приятной работы!</span>

              <span class="number">3</span>
            </div>
          </div>

          <button @click="this.$router.push('/authorization?register=true')">
            Подать заявку сейчас!
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper cloud" v-animateonscroll="getScrollAnimate">
    <img class="left" src="@/views/Landing/images/common/left.svg" alt=""/>
    <img class="right" src="@/views/Landing/images/common/right.svg" alt=""/>

    <div class="section" style="z-index: 2">
      <div class="column" style="z-index: 2">
        <div class="center" style="z-index: 2">
          <h2>Стоимость подписки</h2>

          <div class="price-wrapper">
            <div class="price-container">
              <span class="sum">0 ₽</span>
              <span class="type">подписка без сервиса</span>
<!--
              <span class="type-duration" v-tooltip="{
                content: freeTooltip,
                placement: 'top',
                html: true,
                theme: 'custom-theme',
                showTriggers: ['click', 'touch', 'hover']
              }">
                  Бесплатно
                  
                  <i class="material-icons">help</i>       
              </span>
-->
            </div>
    

            <div class="price-container">
              <span class="sum">75 000 ₽</span>
              <span class="type">подписка с сервисом</span>
<!--
              <span class="type-duration" v-tooltip="{
                content: businessTooltip,
                placement: 'top',
                html: true,
                theme: 'custom-theme',
                showTriggers: ['click', 'touch', 'hover']
              }">
                  Учреждение

                  <i class="material-icons">help</i>
              </span>
-->
            </div>
<!--
            <div class="price-container year">
              <span class="sum">120 000 ₽</span>
              <span class="type">подписка</span>

              <span class="type-duration" v-tooltip="{
                content: premiumTooltip,
                placement: 'top',
                html: true,
                theme: 'custom-theme',
                showTriggers: ['click', 'touch', 'hover']
              }">
                  Премиум

                  <i class="material-icons">help</i>
              </span>
            </div>
-->
          </div>

          <div class="payments-container">
            <span v-tooltip="{
                content: paymentsMethods,
                placement: 'top',
                html: true,
                theme: 'custom-theme',
                showTriggers: ['click', 'touch', 'hover']
              }">
                  Способы оплаты

                  <i class="material-icons">help</i>
              </span>

            <span v-tooltip="{
                content: conditionsRefusal,
                placement: 'top',
                html: true,
                theme: 'custom-theme',
                showTriggers: ['click', 'touch', 'hover']
              }">
                  Условия отказа

                  <i class="material-icons">help</i>
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <div class="center">
<!--          <h2>Также в подписку включено</h2>-->
          <h2>Работа через веб-браузер и приложение«Обращайся»</h2>

          <div class="list-container">
            <div class="list-item">
              <img src="@/views/Landing/images/icon/update.svg" alt=""/>
              <p>Автоматические обновления</p>
            </div>
            <div class="list-item">
              <img src="@/views/Landing/images/icon/sync_alt.svg" alt=""/>
              <p>Клиент-серверный вариант работы для повышения надежности и скорости работы</p>
            </div>
            <div class="list-item">
              <img src="@/views/Landing/images/icon/support_agent.svg" alt=""/>
              <p>Техническая поддержка</p>
            </div>
            <div class="list-item">
              <img src="@/views/Landing/images/icon/school.svg" alt=""/>
              <p>Обучение</p>
            </div>
          </div>

          <div class="images-with-text">
            <img src="@/views/Landing/images/logo.svg" alt=""/>
            <p>-</p>
            <p class="long-text">Официальный Партнер ПАО «СБЕРБАНК»</p>
            <img class="sber" src="@/views/Landing/images/sber_logo.svg" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <div class="center">
          <h2>Единая система корпоративного и межмуниципального сотрудничества</h2>
          <h2 class="gray">Цифровое представительство вашей организации</h2>

          <div class="tile-container">
            <div class="tile">Цифровой сервис вашей организации</div>
            <div class="tile">Онлайн-приемная, услуги местного значения</div>
            <div class="tile">Обмен информацией и сообщество для решения деловых вопросов</div>
            <div class="tile">Поиск на запросы муниципальных нужд и взаимодействие с поставщиками</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper contact-form" v-animateonscroll="getScrollAnimate">
    <div class="section common contact-form">
      <div class="column">
        <div class="center">
          <h2 v-if="showDefault" class="common-form">Появились вопросы?</h2>

          <button v-if="showDefault" @click="openForm" class="common-form">
            Связаться с нами
          </button>

          <div class="callback-form" v-if="showCallbackForm">
            <span>Заполните форму, и мы с Вами свяжемся</span>

            <input class="number"
                   type="text"
                   v-model="name"
                   placeholder="Ваше имя"
            />

            <input class="number"
                   type="text"
                   v-model="organization"
                   placeholder="Организация"
            />

            <input class="number"
                   type="tel"
                   v-maska="'+7 (###) ###-##-##'"
                   v-model="phone_number"
                   placeholder="+7 (000) 000-00-00"
            />

            <label for="iAgree">
              <input class="checkbox"
                     type="checkbox"
                     v-model="iAgree"
                     id="iAgree"
              />
              <span>Я согласен с <a href="https://api.obrashaysya.ru/defaults/docs/privacy_policy.html" target="_blank">политикой обработки персональных данных</a>.</span>
            </label>

            <button @click="send" :disabled="iAgree && (phone_number === '' || phone_number === null) || (name === '' || name === null)">
              Отправить
            </button>
          </div>

          <h2 v-if="callbackSuccess" class="finish-form">Спасибо за отклик!</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>