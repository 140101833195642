import {createRouter, createWebHistory} from 'vue-router'
import ContactAuth from "@/views/Authorization/ContactAuth";
import ContactRegister from "@/views/Registration/NewRegister";
import FederalRegister from "@/views/Registration/FederalRegister";
import Landing from "@/views/Landing/common-page.vue";

const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: Landing,
    },
    {
        path: '/authorization',
        name: 'Contact enter',
        component: ContactAuth,
    },
    {
        path: '/register/:type',
        name: 'ContactRegister',
        component: ContactRegister,
    },
    {
        path: '/register-federal',
        name: 'FederalRegister',
        component: FederalRegister,
    },
    {
        path: '/usage-rules',
        name: 'UsageRules',
        component: () => import('@/views/LegalDocuments/usage-rules'),
    },
    {
        path: '/public-offer',
        name: 'PublicOffer',
        component: () => import('@/views/LegalDocuments/public-offer'),
    },
    {
        path: '/data-policy',
        name: 'DataPolicy',
        component: () => import('@/views/LegalDocuments/data-policy'),
    },
    {
        path: '/platform-info',
        name: 'PlatformInfo',
        component: () => import('@/views/LegalDocuments/platform-info'),
    },
    {
        path: '/user-consent',
        name: 'UserConsent',
        component: () => import('@/views/LegalDocuments/user-consent'),
    },
    {
        path: '/marketplace',
        name: 'Marketplace',
        component: () => import('@/views/LegalDocuments/marketplace'),
    },
    {
        path: '/channel-link',
        name: 'ChannelLink',
        component: () => import('@/views/ChannelLink/channel-link'),
    },
    {
        path: '/stories-link',
        name: 'StoriesLink',
        component: () => import('@/views/PublicStories/public-stories'),
    },
    {
        path: '/channels',
        name: 'Channels',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/managers',
        name: 'Managers',
        component: () => import('@/views/ManagersSelection/main'),
    },
    {
        path: '/channel-creation',
        name: 'ChanelCreation',
        component: () => import('@/views/ChannelSelection/ChanelCreation'),
    },

    {
        path: '/appeals-citizens',
        name: 'AppealsCitizens',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/response-request',
        name: 'ResponseRequest',
        component: () => import('@/views/Appeals/ResponseRequest'),
    },
    {
        path: '/list-citizen',
        name: 'ListCitizen',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/citizen-card',
        name: 'CitizenCard',
        component: () => import('@/components/UserCard/WrapperCard'),
    },
    {
        path: '/register-npa',
        name: 'RegisterNpa',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/documents-forms',
        name: 'DocumentsForms',
        component: () => import('@/views/DocumentsForms/Documents/DocumentsForms'),
    },
    {
        path: '/plug-document',
        name: 'PlugDocument',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/section-development',
        name: 'SectionDevelopment',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/my-channel',
        name: 'My channel',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/my-ai',
        name: 'My ai',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/regulation',
        name: 'Regulation',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/document-constructor',
        name: 'Document constructor',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/chat/:type',
        name: 'Chat',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/department-interaction/:type',
        name: 'DepartmentInteraction',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/map',
        name: 'Map',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/feed',
        name: 'Feed',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/feed-events',
        name: 'FeedEvents',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/feed-common',
        name: 'FeedNew',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/user-info',
        name: 'UserInfo',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/wallet',
        name: 'Wallet',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/show-post/:postId',
        name: 'ShowPost',
        component: () => import('@/views/SinglePost/main'),
    },
    {
        path: '/show-event/:eventId',
        name: 'ShowEvent',
        component: () => import('@/views/PublicEvent/main'),
    },
    {
        path: '/show-product/:productId',
        name: 'ShowProduct',
        component: () => import('@/views/PublicProduct/main'),
    },
    {
        path: '/create-promo/:bloggerId',
        name: 'PublicPromo',
        component: () => import('@/views/PublicPromo/main'),
    },
    {
        path: '/books',
        name: 'Books',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/trade-aggregator',
        name: 'Microtender',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/info-systems',
        name: 'InfoSystems',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/community',
        name: 'Community',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/public',
        name: 'Public',
        component: () => import('@/views/PublicPage/main'),
    },
    {
        path: '/afisha',
        name: 'Afisha',
        component: () => import('@/views/Afisha/main'),
    },
    {
        path: '/promotion',
        name: 'Promotion',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/document-preview',
        name: 'DocumentPreview',
        component: () => import('@/views/DocumentPreview/main'),
    },
    {
        path: '/link-details/:channelId/:detailsType/:detailsId',
        name: 'LinkDetails',
        component: () => import('@/views/main-frame'),
    },
    {
        path: '/remove-account',
        name: 'RemoveAccount',
        component: () => import('@/views/RemoveAccountPage/main'),
    },
    {
        path: '/marking-training-course',
        name: 'TrainingCourse',
        component: () => import('@/views/TrainingCourse/main'),
    },
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = `Обращайся`;
    next();
});

export default router
