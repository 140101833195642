<script>
import "@/views/Landing/style/content.scss";
import NumberAnimation from "vue-number-animation";

export default {
  data: () => ({
    phone_number: null,
    name: null,
    organization: null,
    showDefault: true,
    showCallbackForm: false,
    callbackSuccess: false,
    iAgree: true,
    activeTab: 'Медиаплатформа',
  }),
  emits: [
    'sendQuestion',
    'changeState',
  ],
  name: "department-content",
  components: {
    NumberAnimation,
  },
  computed: {
    getScrollAnimate() {
      return window.innerWidth <= 1152 ? {} : {enterClass: 'fadein', leaveClass: 'fadeout'};
    },
    theFormat() {
      return (number) => {
        return number.toFixed(1);
      }
    },
    theWholeFormat() {
      return (number) => {
        return number.toFixed(0);
      }
    },
  },
  methods: {
    send(event) {
      this.callbackSuccess = true;

      const target = event.currentTarget;
      const parent = target.closest('.center');
      const form = parent.querySelector('.callback-form');

      form.classList.remove('fadeOpacity');
      form.classList.add('fadeOpacityNone');

      this.$emit('sendQuestion', {phone: this.phone_number, name: this.name, organization: this.organization});

      setTimeout(() => {
        this.showCallbackForm = false;

        const finish = parent.querySelector('.finish-form');

        finish.classList.add('fadeOpacity');
      }, 500);
    },
    openForm(event) {
      this.showCallbackForm = true;

      const target = event.currentTarget;
      const parent = target.closest('.center');
      const common = parent.querySelectorAll('.common-form');

      common.forEach(el => {
        el.classList.add('fadeOpacityNone');
      });

      setTimeout(() => {
        this.showDefault = false;

        const form = parent.querySelector('.callback-form');

        form.classList.add('fadeOpacity');
        form.scrollIntoView(false);
      }, 500);
    },
    handleIntersectionTwo(entries) {
      if (entries) {
        this.$refs.animationNumber2.restart();
        this.$refs.animationNumber3.restart();
        // this.$refs.animationNumber4.restart();
        this.$refs.animationNumber5.restart();
      }
    },
    openChannel(channelId) {
      const link = document.createElement('a');

      link.href = `https://api.obrashaysya.ru/show-channel/${channelId}`;
      link.target = '_blank';
      link.click();
      link.remove();
    },
  },
}
</script>

<template>
  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section department">
      <div class="column">
        <button @click="$emit('changeState', 'common')" class="go-back-btn">
          <img src="@/views/Landing/images/icon/arrow_back_ios_new.svg" alt=""/>
          на главную
        </button>

        <h2>Цифровой помощник в работе муниципальных учреждений</h2>

        <span>Работает на основе специализированной модели искусственного интеллекта для работы с обращениями</span>
        <span style="font-weight: normal;">Сделано в России</span>

        <div class="group">
          <button @click="this.$router.push('/authorization?register=true')" style="height: 60px">Зарегистрироваться
          </button>
        </div>
      </div>

      <img class="mobile-hide" src="@/views/Landing/images/common/department.svg" alt=""/>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section column">
      <div class="column">
        <div class="center text-center">
          <div class="group-row evenly">
            <img class="mini" src="@/views/Landing/images/mincif.svg" alt=""/>
            <img class="mini mobile-minor" src="@/views/Landing/images/ros_pat.svg" alt=""/>
          </div>

          <p>
            Платформа зарегистрирована в РОСПАТЕНТ Российской Федерации как Программное обеспечение
            ЭВМ «Обращайся» свидетельствомо государственной регистрации под No2022660938 от 29.06.2022 г., а также
            входит в Единый реестр российских программ Министерство цифровизации РФ под
            Ne16360 от 23.01.2023 г.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section column">
      <div class="column">
        <div class="center">
          <h2>Что вы получаете <span style="color: var(--landing-color-red)">?</span></h2>

          <div class="card-container">
            <div class="card-item">
            <span>
              Решение вопросов местного значения
            </span>

              <img src="@/views/Landing/images/common/dep-ic-1.svg" alt=""/>
            </div>

            <div class="card-item">
            <span>
              Доверие и удовлетворенность населения
            </span>

              <img src="@/views/Landing/images/common/dep-ic-2.svg" alt=""/>
            </div>

            <div class="card-item">
            <span>
              Разгрузка работы сотрудников до 70%
            </span>

              <img src="@/views/Landing/images/common/dep-ic-3.svg" alt=""/>
            </div>

            <div class="card-item">
            <span>
              Качественное оказание услуг гражданам 
            </span>

              <img src="@/views/Landing/images/common/dep-ic-5.svg" alt=""/>
            </div>

            <div class="card-item">
            <span>
              Повышение компетенции сотрудников при работе с обращениями
            </span>

              <img src="@/views/Landing/images/common/buisness-icon-3.svg" alt=""/>
            </div>

            <div class="card-item">
            <span>
              Проведение опросов и голосований при инициативном бюджетировании  
            </span>

              <img src="@/views/Landing/images/common/dep-ic-6.svg" alt=""/>
            </div>

            <div class="card-item">
            <span>
              Любой ответ за 1 минуту 
            </span>

              <img src="@/views/Landing/images/common/buisness-icon-1.svg" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper diagonal-bg" v-animateonscroll="getScrollAnimate">
    <div class="diagonal"/>
    <div class="section column">
      <div class="column">
        <div class="center">
          <h2>Подробнее</h2>

          <div class="tile-container max-center dep">
            <div>
              <div class="tile" :class="{active: activeTab === 'Медиаплатформа'}"
                   @click="() => activeTab = 'Медиаплатформа'">Медиаплатформа
              </div>
              <div class="tile" :class="{active: activeTab === 'Онлайн приемная'}"
                   @click="() => activeTab = 'Онлайн приемная'">Онлайн приемная
              </div>
            </div>
            <div>
              <div class="tile" :class="{active: activeTab === 'Опросы и голосования'}"
                   @click="() => activeTab = 'Опросы и голосования'">Опросы и голосования
              </div>
              <div class="tile" :class="{active: activeTab === 'Мессенджер'}" @click="() => activeTab = 'Мессенджер'">
                Мессенджер
              </div>
            </div>
            <div>
              <div class="tile" :class="{active: activeTab === 'Документы'}" @click="() => activeTab = 'Документы'">
                Документы
              </div>
            </div>
          </div>

          <span class="details-item" v-if="activeTab === 'Медиаплатформа'">
          <span class="bold">Медиаплатформа</span> – позволяет вести информационную работутерриториально по муниципальному образованию и региону , по одной кнопке публиковать в Госпабликиорганизации.
        </span>

          <span class="details-item" v-if="activeTab === 'Онлайн приемная'">
          <span class="bold">Онлайн приемная</span> – настройка и администрирование муниципальных услуг, местных вопросов по которым ведется общение и обработка обратной связи с населением. Отправка уведомлений и оповещений в личные аккаунты в приложении «Обращайся». Обращения поступают только по заданной тематике.
        </span>

          <span class="details-item" v-if="activeTab === 'Опросы и голосования'">
          <span class="bold">Опросы и голосования</span> – инструмент инициативного бюджетирования, проведение опросов и голосований с выгрузкой данных и протоколом голосования.
        </span>

          <span class="details-item" v-if="activeTab === 'Мессенджер'">
          <span class="bold">Мессенджер</span> – Российский мессенджер для общения и безопасной командной работы. Быстрый доступ на сотрудника любой компании по месту работы, занимаемой должности для личного и рабочего общения. Поиск ведется по наименованию учреждения и месту работы служащего. Организации и чат с организациями для коммуникации между организациями, который соединяет всех пользователей кабинета в одну экосистему.
        </span>

          <span class="details-item" v-if="activeTab === 'Документы'">
          <span class="bold">Документы</span> – хранение и редактирование документов постоянного рабочего пользования, для обмена данными .Типовые нормативные акты муниципальных учреждений.
        </span>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section bg">
      <div class="column">
        <div class="center">
          <h2>В нашей системе</h2>

          <div class="card-container mini-text" v-intersect="handleIntersectionTwo">
            <div class="card-item only-text">
              <div class="group-row">
                <span> > </span>

                <NumberAnimation
                    ref="animationNumber2"
                    :from="0.0"
                    :to="1000.0"
                    :duration="2"
                    :format="theFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>

              <p>общее число обрабатываемых обращений в день</p>
            </div>

            <div class="card-item only-text">
              <div class="group-row">
                <NumberAnimation
                    ref="animationNumber3"
                    :from="0.0"
                    :to="1.0"
                    :duration="2"
                    :format="theFormat"
                    :autoplay="false"
                    easing="linear"
                />

                <span>минуты</span>
              </div>

              <p>среднее время обработки одного обращения</p>
            </div>

            <div class="card-item only-text">
              <div class="group-row">
                <span>моментально</span>
              </div>

              <p>сервис отвечает на запросы и вопросы</p>
            </div>

            <div class="card-item only-text">
              <div class="group-row">
                <span>в одной системе</span>
              </div>

              <p>общение и уточнение вопросов в единой системе</p>
            </div>

            <div class="card-item only-text">
              <div class="group-row">
                <NumberAnimation
                    ref="animationNumber5"
                    :from="0.0"
                    :to="100"
                    :duration="2"
                    :format="theFormat"
                    :autoplay="false"
                    easing="linear"
                />

                <span>+</span>
              </div>

              <p>поставщиков для решения нужд муниципалитетов</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <div class="center">
          <img class="micro" src="@/views/Landing/images/big-icon/government_2.svg" alt=""/>

          <div class="group-row group-center">
            <h2>С нами работает более 300</h2>
          </div>

          <span class="align-center">бюджетных организаций Республики Саха (Якутия)</span>

          <div class="items-wrap">
            <div class="items marquee">
              <div class="item" @click="openChannel('462')">
                <img src="@/views/Landing/images/company/MKU.svg" alt=""/>

                <span>Управа Сайсарского округа" МКУ ГО "город Якутск"</span>
              </div>

              <div class="item" @click="openChannel('559')">
                <img src="@/views/Landing/images/company/gos_tr.svg" alt=""/>

                <span>Государственный театр эстрады РС(Я)</span>
              </div>

              <div class="item" @click="openChannel('172')">
                <img src="@/views/Landing/images/company/saha_tr.svg" alt=""/>

                <span>Саха Театра</span>
              </div>

              <div class="item" @click="openChannel('441')">
                <img src="@/views/Landing/images/company/CIT.svg" alt=""/>

                <span>МКУ ЦИТ</span>
              </div>

              <div class="item" @click="openChannel('443')">
                <img src="@/views/Landing/images/company/kid_nas.svg" alt=""/>

                <span>Администрация Тулагино-Кильдямского наслега</span>
              </div>
            </div>

            <div class="items marquee" aria-hidden="true">
              <div class="item" @click="openChannel('462')">
                <img src="@/views/Landing/images/company/MKU.svg" alt=""/>

                <span>Управа Сайсарского округа" МКУ ГО "город Якутск"</span>
              </div>

              <div class="item" @click="openChannel('559')">
                <img src="@/views/Landing/images/company/gos_tr.svg" alt=""/>

                <span>Государственный театр эстрады РС(Я)</span>
              </div>

              <div class="item" @click="openChannel('172')">
                <img src="@/views/Landing/images/company/saha_tr.svg" alt=""/>

                <span>Саха Театра</span>
              </div>

              <div class="item" @click="openChannel('441')">
                <img src="@/views/Landing/images/company/CIT.svg" alt=""/>

                <span>МКУ ЦИТ</span>
              </div>

              <div class="item" @click="openChannel('443')">
                <img src="@/views/Landing/images/company/kid_nas.svg" alt=""/>

                <span>Администрация Тулагино-Кильдямского наслега</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <div class="center">
          <img class="micro" src="@/views/Landing/images/big-icon/government_1.svg" alt=""/>

          <div class="group-row group-center">
            <h2>Более 200</h2>
          </div>

          <span class="align-center">поставщиков товаров и услуг на нужды вопросов местного значения</span>

          <div class="items-wrap">
            <div class="items marquee reverce">
              <div class="item">
                <img src="@/views/Landing/images/company/chiva.svg" alt=""/>

                <span>CHI_VA 03</span>

                <p>Услуги съемки видеоконтента</p>
              </div>

              <div class="item">
                <img src="@/views/Landing/images/company/prioritet.svg" alt=""/>

                <span>ПРИОРИТЕТ</span>

                <p>Продажа недвижимости</p>
              </div>

              <div class="item" @click="openChannel('736')">
                <img src="@/views/Landing/images/company/yakutia.svg" alt=""/>

                <span>VISIT YAKUTIA</span>

                <p>Агентство развития туризма</p>
              </div>

              <div class="item" @click="openChannel('792')">
                <img src="@/views/Landing/images/company/lenskie.svg" alt=""/>

                <span>Ленские Высоты</span>

                <p>Продажа недвижимости</p>
              </div>

              <div class="item" @click="openChannel('4')">
                <img src="@/views/Landing/images/company/cinema.svg" alt=""/>

                <span>Кинотеатр "СИНЕМА-ЦЕНТР"</span>

                <p>Кино</p>
              </div>

              <div class="item" @click="openChannel('380')">
                <img src="@/views/Landing/images/company/azs.svg" alt=""/>

                <span>Туймаада Нефть</span>

                <p>Продажа топлива</p>
              </div>

              <div class="item" @click="openChannel('417')">
                <img src="@/views/Landing/images/company/polar.svg" alt=""/>

                <span>Полярные авиалинии</span>

                <p>Авиакомпания</p>
              </div>

              <div class="item" @click="openChannel('474')">
                <img src="@/views/Landing/images/company/megafon.svg" alt=""/>

                <span>МегаФон Про Бизнес</span>

                <p>Мобильный оператор</p>
              </div>

              <div class="item" @click="openChannel('535')">
                <img src="@/views/Landing/images/company/bread.svg" alt=""/>

                <span>Якутский хлебокомбинат</span>

                <p>Хлебобулочные изделия</p>
              </div>
            </div>

            <div class="items marquee reverce" aria-hidden="true">
              <div class="item" @click="openChannel('4')">
                <img src="@/views/Landing/images/company/cinema.svg" alt=""/>

                <span>Кинотеатр "СИНЕМА-ЦЕНТР"</span>

                <p>Кино</p>
              </div>

              <div class="item" @click="openChannel('380')">
                <img src="@/views/Landing/images/company/azs.svg" alt=""/>

                <span>Туймаада Нефть</span>

                <p>Продажа топлива</p>
              </div>

              <div class="item" @click="openChannel('417')">
                <img src="@/views/Landing/images/company/polar.svg" alt=""/>

                <span>Полярные авиалинии</span>

                <p>Авиакомпания</p>
              </div>

              <div class="item" @click="openChannel('474')">
                <img src="@/views/Landing/images/company/megafon.svg" alt=""/>

                <span>МегаФон Про Бизнес</span>

                <p>Мобильный оператор</p>
              </div>

              <div class="item" @click="openChannel('535')">
                <img src="@/views/Landing/images/company/bread.svg" alt=""/>

                <span>Якутский хлебокомбинат</span>

                <p>Хлебобулочные изделия</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <div class="center">
          <h2>Проверенные поставщики</h2>
          <h2 class="gray">для нужд организации и обеспечения полномочий поступают по всем направлениям и сферам
            деятельности:</h2>

          <div class="tile-container">
            <div class="tile">Поставка оборудования и компьютерной техники</div>
            <div class="tile">Поставка объектов благоустройства</div>
            <div class="tile">Поставка техники и комплектующих с/х назначения</div>
            <div class="tile">Поставка противопожарного и светового оборудования</div>
            <div class="tile">Разработка проектно-сметных и иных документаций</div>
            <div class="tile">Поставка канцтоваров</div>
            <div class="tile">Поставка военного снаряжения</div>
            <div class="tile">Поставка сувенирной и подарочной продукции</div>
            <div class="tile">Полиграфические услуги</div>
            <div class="tile">Поставка нефтепродуктов</div>
            <div class="tile">Услуги грузоперевозки</div>
            <div class="tile">Услуги строительства и ремонта зданий</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">-->
  <!--    <div class="section">-->
  <!--      <div class="column">-->
  <!--        <div class="center">-->
  <!--          <h2>Инструменты программного обеспечения</h2>-->

  <!--          <div class="image-column-block">-->
  <!--            <div class="image-column-item">-->
  <!--              <img src="@/views/Landing/images/web_1.svg" alt="" />-->

  <!--              <p>Цифровой кабинет</p>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">-->
  <!--    <div class="section">-->
  <!--      <div class="column">-->
  <!--        <div class="center">-->
  <!--          <div class="image-column-block">-->
  <!--            <div class="image-column-item">-->
  <!--              <img src="@/views/Landing/images/web_2.svg" alt="" />-->

  <!--              <p>Реестр обращений</p>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">-->
  <!--    <div class="section">-->
  <!--      <div class="column">-->
  <!--        <div class="center">-->
  <!--          <div class="image-column-block">-->
  <!--            <div class="image-column-item">-->
  <!--              <img src="@/views/Landing/images/web_3.svg" alt="" />-->

  <!--              <p>Микротендер - товары и услуги</p>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <div class="section-wrapper contact-form" v-animateonscroll="getScrollAnimate">
    <div class="section department contact-form">
      <div class="column">
        <div class="center">
          <h2 v-if="showDefault" class="common-form">Появились вопросы?</h2>

          <button v-if="showDefault" @click="openForm" class="common-form">
            Связаться с нами
          </button>

          <div class="callback-form" v-if="showCallbackForm">
            <span>Заполните форму, и мы с Вами свяжемся</span>

            <input class="number"
                   type="text"
                   v-model="name"
                   placeholder="Ваше имя"
            />

            <input class="number"
                   type="text"
                   v-model="organization"
                   placeholder="Организация"
            />

            <input class="number"
                   type="tel"
                   v-maska="'+7 (###) ###-##-##'"
                   v-model="phone_number"
                   placeholder="+7 (000) 000-00-00"
            />

            <label for="iAgree">
              <input class="checkbox"
                     type="checkbox"
                     v-model="iAgree"
                     id="iAgree"
              />
              <span>Я согласен с <a href="https://api.obrashaysya.ru/defaults/docs/privacy_policy.html" target="_blank">политикой обработки персональных данных</a>.</span>
            </label>

            <button @click="send"
                    :disabled="iAgree && (phone_number === '' || phone_number === null) || (name === '' || name === null)">
              Отправить
            </button>
          </div>

          <h2 v-if="callbackSuccess" class="finish-form">Спасибо за отклик!</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>