<script>
import "@/views/Landing/style/content.scss";
import NumberAnimation from "vue-number-animation";

export default {
  data: () => ({
    phone_number: null,
    name: null,
    organization: null,
    showDefault: true,
    showCallbackForm: false,
    callbackSuccess: false,
    iAgree: true,
    activeTab: 'Реклама',
    freeTooltip: '<strong>Бесплатно:</strong>' +
        '<p>&nbsp;&nbsp;∘ Публикация заказов на агрегаторе торговли.</p>' +
        '<p>&nbsp;&nbsp;∘ Поиск и переписка с организациями.</p>' +
        '<p>&nbsp;&nbsp;∘ Мессенджер для общения с сотрудниками других организаций.</p>' +
        '<p>&nbsp;&nbsp;∘ Публикация и В2С продажа товаров и услуг в приложении «Обращайся».</p>' +
        '<p>&nbsp;&nbsp;∘ Ведение контента в приложении «Обращайся».</p>',
    businessTooltip: '<strong>Бизнес подписка на 1 год 52 000:</strong>' +
        '<p>&nbsp;&nbsp;∘ Публикация предложений на поставку товаров и услуг на витрине.</p>' +
        '<p>&nbsp;&nbsp;∘ Доступ к заявкам и заказам поставщиков, с прямыми контактами.</p>' +
        '<p>&nbsp;&nbsp;∘ Публикация заказов на агрегаторе торговли.</p>' +
        '<p>&nbsp;&nbsp;∘ Поиск и переписка с организациями.</p>' +
        '<p>&nbsp;&nbsp;∘ Мессенджер для общения с сотрудниками других организаций.</p>' +
        '<p>&nbsp;&nbsp;∘ Публикация и В2С продажа товаров и услуг в приложении «Обращайся».</p>' +
        '<p>&nbsp;&nbsp;∘ Ведение контента в приложении «Обращайся».</p>',
    premiumTooltip: '<strong>Премиум подписка на 1 год 120 000:</strong>' +
        '<p>&nbsp;&nbsp;∘ Закрепление предложений на первых местах в агрегаторе торговли.</p>' +
        '<p>&nbsp;&nbsp;∘ Показы товаров , услуг и предложений для всех регионов.</p>' +
        '<p>&nbsp;&nbsp;∘ Рекламный баннер в кабинетах со ссылкой на мессенджер или любые другие ресурсы.</p>' +
        '<p>&nbsp;&nbsp;∘ Публикация предложений на поставку товаров и услуг на витрине.</p>' +
        '<p>&nbsp;&nbsp;∘ Доступ к заявкам и заказам поставщиков, с прямыми контактами.</p>' +
        '<p>&nbsp;&nbsp;∘ Публикация заказов на агрегаторе торговли.</p>' +
        '<p>&nbsp;&nbsp;∘ Поиск и переписка с организациями.</p>' +
        '<p>&nbsp;&nbsp;∘ Мессенджер для общения с сотрудниками других организаций.</p>' +
        '<p>&nbsp;&nbsp;∘ Публикация и В2С продажа товаров и услуг в приложении «Обращайся».</p>' +
        '<p>&nbsp;&nbsp;∘ Ведение контента в приложении «Обращайся».</p>',
    paymentsMethods: '<strong>Способы оплаты:</strong>' +
        '<p>Принимаем к оплате карты платёжных систем VISA, MasterCard, МИР.</p>' +
        '<p>Чтобы оплатить товар банковской картой, выберите этот способ оплаты при оформлении заказа. Далее перейдите на платёжную страницу банка и введите данные вашей банковской карты:</p>' +
        '<p>&nbsp;&nbsp;&nbsp;∘ номер;</p> ' +
        '<p>&nbsp;&nbsp;&nbsp;∘ срок действия;</p> ' +
        '<p>&nbsp;&nbsp;&nbsp;∘ трёхзначный CVC2/CVV2 код (указан на оборотной стороне).</p>' +
        '<p>Если ваша карта подключена к услуге 3D-Secure, вы будете автоматически переадресованы на страницу банка, который выпустил карту, для аутентификации.</p> ' +
        '<p>Интернет-платежи обрабатываются через безопасный шлюз банка согласно международному сертификату безопасности PCI DSS. Информация шифруется по технологии SSL и недоступна посторонним лицам».</p>',
    conditionsRefusal: '<strong>Условия отказа:</strong>' +
        '<p>&nbsp;&nbsp;&nbsp;∘  По закону «О защите прав потребителей» вы можете расторгнуть договор об оказании услуги в любое время. При этом часть услуг, которые уже были оказаны, нужно оплатить.</p>' +
        '<p>&nbsp;&nbsp;&nbsp;∘  При оплате банковской картой деньги вернутся на ту карту, с которой был сделан платёж. Срок возврата — от 1 до 30 рабочих дней.</p>',
  }),
  emits: [
    'sendQuestion',
    'changeState',
  ],
  name: "business-content",
  components: {
    NumberAnimation,
  },
  computed: {
    getScrollAnimate() {
      return window.innerWidth <= 1152 ? {} : {enterClass: 'fadein', leaveClass: 'fadeout'};
    },
    theFormat() {
      return (number) => {
        return number.toFixed(1);
      }
    },
    theWholeFormat() {
      return (number) => {
        return number.toFixed(0);
      }
    },
  },
  methods: {
    send(event) {
      this.callbackSuccess = true;

      const target = event.currentTarget;
      const parent = target.closest('.center');
      const form = parent.querySelector('.callback-form');

      form.classList.remove('fadeOpacity');
      form.classList.add('fadeOpacityNone');

      this.$emit('sendQuestion', {phone: this.phone_number, name: this.name, organization: this.organization});

      setTimeout(() => {
        this.showCallbackForm = false;

        const finish = parent.querySelector('.finish-form');

        finish.classList.add('fadeOpacity');
      }, 500);
    },
    openForm(event) {
      this.showCallbackForm = true;

      const target = event.currentTarget;
      const parent = target.closest('.center');
      const common = parent.querySelectorAll('.common-form');

      common.forEach(el => {
        el.classList.add('fadeOpacityNone');
      });

      setTimeout(() => {
        this.showDefault = false;

        const form = parent.querySelector('.callback-form');

        form.classList.add('fadeOpacity');
        form.scrollIntoView(false);
      }, 500);
    },
    handleIntersection(entries) {
      if (entries) {
        this.$refs.animationNumber1.restart();
        this.$refs.animationNumber2.restart();
        this.$refs.animationNumber3.restart();
        this.$refs.animationNumber4.restart();
        this.$refs.animationNumber5.restart();
      }
    },
    openChannel(channelId) {
      const link = document.createElement('a');

      link.href = `https://api.obrashaysya.ru/show-channel/${channelId}`;
      link.target = '_blank';
      link.click();
      link.remove();
    },
  },
}
</script>

<template>
  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <button @click="$emit('changeState', 'common')" class="go-back-btn">
          <img src="@/views/Landing/images/icon/arrow_back_ios_new.svg" alt=""/>
          на главную
        </button>

        <h2 class="header-title">Поставки товаров и услуг на муниципальные нужды</h2>
        <!--
        <span style="margin-top: -1.2em;">твоего бизнеса</span>
        <span style="font-weight: normal;">Помогаем компаниям малого и среднего бизнеса масштабироваться, повышать узнаваемость, увеличить продажи</span>
        -->
        <div class="tile-container left">
          <div class="tile">Прямые договора</div>
          <div class="tile">Постоянные контакты</div>
          <div class="tile">Долгосрочные решения</div>
        </div>

        <div class="group">
          <button @click="this.$router.push('/authorization?register=true')" style="height: 60px">Зарегистрироваться</button>
        </div>
      </div>

      <img src="@/views/Landing/images/common/buisness-1-img.svg" alt=""/>
    </div>
  </div>

<!--  <div class="section column" v-animateonscroll="getScrollAnimate">-->
<!--    <div class="column">-->
<!--      <div class="center">-->
<!--        <img class="mini" src="@/views/Landing/images/Frame_254.svg" alt=""/>-->

<!--        <span>Попробуйте прямо сейчас!</span>-->

<!--        <img class="maxi" src="@/views/Landing/images/Frame_256.svg" alt=""/>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section column">
      <div class="column">
        <div class="center">
          <h2>Что мы предлагаем <span style="color: var(--landing-color-red)">?</span></h2>

          <div class="card-container">
            <div class="card-item">
            <span>
              Реклама на целевую аудиторию
            </span>

              <img src="@/views/Landing/images/common/buisness-icon-1.svg" alt="" />
            </div>

            <div class="card-item">
            <span>
              Выход в регионы
            </span>

              <img src="@/views/Landing/images/common/buisness-icon-2.svg" alt="" />
            </div>

            <div class="card-item">
            <span>
              Прямое общение
            </span>

              <img src="@/views/Landing/images/common/buisness-icon-3.svg" alt="" />
            </div>

            <div class="card-item">
            <span>
              Прямые продажи
            </span>

              <img src="@/views/Landing/images/common/buisness-icon-4.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper diagonal-bg" v-animateonscroll="getScrollAnimate">
    <div class="diagonal" />
    <div class="section column">
      <div class="column">
        <div class="center">
          <h2>Подробнее</h2>

          <div class="tile-container max-center">
            <div>
              <div class="tile" :class="{active: activeTab === 'Реклама'}" @click="() => activeTab = 'Реклама'">Реклама</div>
              <div class="tile" :class="{active: activeTab === 'Маркет'}" @click="() => activeTab = 'Маркет'">Маркет</div>
              <div class="tile" :class="{active: activeTab === 'Агрегатор'}" @click="() => activeTab = 'Агрегатор'">Агрегатор</div>
            </div>
            <div>
              <div class="tile" :class="{active: activeTab === 'Общение'}" @click="() => activeTab = 'Общение'">Общение</div>
              <div class="tile" :class="{active: activeTab === 'ИИ'}" @click="() => activeTab = 'ИИ'">ИИ</div>
              <div class="tile" :class="{active: activeTab === 'Приложение'}" @click="() => activeTab = 'Приложение'">Приложение</div>
            </div>
          </div>

          <span class="details-item" v-if="activeTab === 'Реклама'">
          <span class="bold">Реклама</span> – удобная публикация медиа файлов, сервис первичного учета и маркировки интернет-рекламы. Он позволяет соблюдать все требования закона «О рекламе», создает реестр быстрого доступа на все документы, ссылки, файлы, контрагентам, токенам и обеспечивает полный учет и отчетность всех действий.
        </span>

          <span class="details-item" v-if="activeTab === 'Маркет'">
          <span class="bold">Маркет</span> – классическая витрина товаров, услуг, событий. Они публикуются и редактируются с личного кабинета, удобно ставить любую ссылку с переходом на ваши ресурсы. Дополнительно можно настроить продажи на личный кабинет, с платежной системой Юкасса.
        </span>

          <span class="details-item" v-if="activeTab === 'Агрегатор'">
          <span class="bold">Агрегатор</span> – новый формат В2В продаж, представляющий с собой торговлю через общение на прямые заказы. Система позволяет выбирать поставщика по его наиболее выгодному предложению, который приходит с уведомлениями о заказах и поставках.
        </span>

          <span class="details-item" v-if="activeTab === 'Общение'">
          <span class="bold">Общение</span> – быстрый доступ на сотрудника любой компании по месту работы, занимаемой должности для личного и рабочего общения . Поиск ведется по наименованию компании, бренда. Организации и чат с организациями для коммуникации между организациями, который соединяет всех пользователей кабинета в одну экосистему.
        </span>

          <span class="details-item" v-if="activeTab === 'ИИ'">
          <span class="bold">Искусственный интеллект</span> – ассистент секретарь помощник, который напишет для вас базовые и примерные тексты, ответит на вопросы.
        </span>

          <span class="details-item" v-if="activeTab === 'Приложение'">
          <span class="bold">Приложение</span> – через личный кабинет администрируете свой канал бизнеса в приложении «Обращайся». Публикация контента, рекламная информация, витрина товаров, услуг и событий доступно для всехпользователей. Подписчикам вашего канала приходит короткое всплывающее сообщение, Push-уведомление в приложении, которое увеличивает конверсию продаж и обратную связь.
        </span>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <div class="center">
          <h2>В системе «Обращайся» уже работают</h2>

          <div class="items-wrap">
            <div class="items marquee">
              <div class="item">
                <img src="@/views/Landing/images/company/chiva.svg" alt=""/>

                <span>CHI_VA 03</span>

                <p>Услуги съемки видеоконтента</p>
              </div>

              <div class="item">
                <img src="@/views/Landing/images/company/prioritet.svg" alt=""/>

                <span>ПРИОРИТЕТ</span>

                <p>Продажа недвижимости</p>
              </div>

              <div class="item" @click="openChannel('736')">
                <img src="@/views/Landing/images/company/yakutia.svg" alt=""/>

                <span>VISIT YAKUTIA</span>

                <p>Агентство развития туризма</p>
              </div>

              <div class="item" @click="openChannel('792')">
                <img src="@/views/Landing/images/company/lenskie.svg" alt=""/>

                <span>Ленские Высоты</span>

                <p>Продажа недвижимости</p>
              </div>

              <div class="item" @click="openChannel('4')">
                <img src="@/views/Landing/images/company/cinema.svg" alt="" />

                <span>Кинотеатр "СИНЕМА-ЦЕНТР"</span>

                <p>Кино</p>
              </div>

              <div class="item" @click="openChannel('380')">
                <img src="@/views/Landing/images/company/azs.svg" alt="" />

                <span>Туймаада Нефть</span>

                <p>Продажа топлива</p>
              </div>

              <div class="item" @click="openChannel('417')">
                <img src="@/views/Landing/images/company/polar.svg" alt="" />

                <span>Полярные авиалинии</span>

                <p>Авиакомпания</p>
              </div>

              <div class="item" @click="openChannel('474')">
                <img src="@/views/Landing/images/company/megafon.svg" alt="" />

                <span>МегаФон Про Бизнес</span>

                <p>Мобильный оператор</p>
              </div>

              <div class="item" @click="openChannel('535')">
                <img src="@/views/Landing/images/company/bread.svg" alt="" />

                <span>Якутский хлебокомбинат</span>

                <p>Хлебобулочные изделия</p>
              </div>
            </div>

            <div class="items marquee" aria-hidden="true">
              <div class="item" @click="openChannel('4')">
                <img src="@/views/Landing/images/company/cinema.svg" alt="" />

                <span>Кинотеатр "СИНЕМА-ЦЕНТР"</span>

                <p>Кино</p>
              </div>

              <div class="item" @click="openChannel('380')">
                <img src="@/views/Landing/images/company/azs.svg" alt="" />

                <span>Туймаада Нефть</span>

                <p>Продажа топлива</p>
              </div>

              <div class="item" @click="openChannel('417')">
                <img src="@/views/Landing/images/company/polar.svg" alt="" />

                <span>Полярные авиалинии</span>

                <p>Авиакомпания</p>
              </div>

              <div class="item" @click="openChannel('474')">
                <img src="@/views/Landing/images/company/megafon.svg" alt="" />

                <span>МегаФон Про Бизнес</span>

                <p>Мобильный оператор</p>
              </div>

              <div class="item" @click="openChannel('535')">
                <img src="@/views/Landing/images/company/bread.svg" alt="" />

                <span>Якутский хлебокомбинат</span>

                <p>Хлебобулочные изделия</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section bg">
      <div class="column">
        <div class="center">
          <h2>Наши кейсы и темпы роста</h2>

          <div class="card-container" v-intersect="handleIntersection">
            <div class="card-item only-text">
              <div class="group-row">
                <span>более</span>

                <NumberAnimation
                    ref="animationNumber1"
                    :from="0"
                    :to="1000"
                    :duration="2"
                    :format="theWholeFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>

              <p>учреждений</p>
            </div>

            <div class="card-item only-text">
              <div class="group-row">
                <span>до</span>

                <NumberAnimation
                    ref="animationNumber2"
                    :from="0"
                    :to="100000"
                    :duration="2"
                    :format="theWholeFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>

              <p>охвата новой аудитории</p>
            </div>

            <div class="card-item only-text">
              <div class="group-row">
                <span>более</span>

                <NumberAnimation
                    ref="animationNumber3"
                    :from="0"
                    :to="200"
                    :duration="2"
                    :format="theWholeFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>

              <p>отзывов от покупателей</p>
            </div>

            <div class="card-item only-text">
              <div class="group-row">
                <span>более</span>

                <NumberAnimation
                    ref="animationNumber4"
                    :from="0"
                    :to="10000"
                    :duration="2"
                    :format="theWholeFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>

              <p>успешно поставок</p>
            </div>

            <div class="card-item only-text">
              <div class="group-row">
                <NumberAnimation
                    ref="animationNumber5"
                    :from="0.0"
                    :to="4.7"
                    :duration="2"
                    :format="theFormat"
                    :autoplay="false"
                    easing="linear"
                />
              </div>

              <p>средняя оценка товаров</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section border">
      <div class="column">
        <div class="center">
          <h2>Из любого устройства и любой точки мира</h2>

          <div class="image-column">
            <div class="item">
              <img src="@/views/Landing/images/telephone.svg" alt="" />

              <span>Через мобильное приложение</span>
            </div>

            <div class="item">
              <img src="@/views/Landing/images/macbook.svg" alt="" />

              <span>Через веб-браузер</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <div class="center">
          <h2>Отзывы наших клиентов</h2>

          <div class="comment-wrapper">
            <div class="comment">
              <div>
                <img src="@/views/Landing/images/common/1client.svg" alt="" />
                <span class="bold">Владелец компании "Азбука",
Нина Васильевна Семенова
г. Якутск</span>
              </div>

              <span class="normal">Очень хороший продукт! Всем рекомендую!</span>
            </div>

            <div class="comment">
              <div>
                <img src="@/views/Landing/images/common/2client.svg" alt="" />
                <span class="bold">ГАУ РС(Я) «Агентство развития туризма и территориального маркетинга»,
врио генерального директора:
Ксенофонтов Аркадий Анатольевич</span>
              </div>

              <span class="normal">Система "Обращайся" помогает быстро найти нужного специалиста или компанию, а также получить качественные и проверенные услуги. Удобный интерфейс и возможность оценить работу специалистов делает использование приложения максимально эффективным и удобным.</span>
            </div>
          </div>

          <div class="comment-wrapper">
            <div class="comment">
              <div>
                <img src="@/views/Landing/images/common/3client.svg" alt="" />
                <span class="bold">Директор ООО «СТОК-АЭРО»,
Токарев Станислав Сергеевич
г. Санкт-Петербург</span>
              </div>

              <span class="normal">Более года мы пользуемся услугами ООО «САХАВИЗИОН», через предоставленный  сервис «Обращайся». Мы продолжаем пользоваться сервисом «ОБРАЩАЙСЯ» и надеемся на дальнейшее  плодотворное сотрудничество.</span>
            </div>

            <div class="comment">
              <div>
                <img src="@/views/Landing/images/common/4client.svg" alt="" />
                <span class="bold">Бизнес коуч, предприниматель,
Нина Шарина
г. Москва</span>
              </div>

              <span class="normal">Как предприниматель с 23 летним стажем , человек имеющий огромную команду по всей России и СНГ, рекомендую бизнес компаниям зарегистрироваться и начинать продавать.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper" v-animateonscroll="getScrollAnimate">
    <div class="section">
      <div class="column">
        <div class="center">
          <h2>Регистрация в системе</h2>

          <span>происходит с действительными реквизитами организации</span>

          <div class="schema">
            <div class="schema-item">
              <span class="text">Оставьте заявку на сайте, пройдите проверку</span>

              <span class="number line">1</span>
            </div>

            <div class="schema-item reverse">
              <span class="text">Получите логин и пароль от личного кабинета</span>

              <span class="number line">2</span>
            </div>

            <div class="schema-item">
              <span class="text">Начните вести бизнес-процессы и приятной работы!</span>

              <span class="number">3</span>
            </div>
          </div>

          <button @click="this.$router.push('/authorization?register=true')">
            Подать заявку сейчас!
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper cloud" v-animateonscroll="getScrollAnimate">
    <img class="left" src="@/views/Landing/images/common/left.svg" alt=""/>
    <img class="right" src="@/views/Landing/images/common/right.svg" alt=""/>

    <div class="section" style="z-index: 2">
      <div class="column" style="z-index: 2">
        <div class="center" style="z-index: 2">
          <h2>Стоимость подписки</h2>

          <div class="price-wrapper">
            <div class="price-container">
              <span class="sum">0 ₽</span>
              <span class="type">подписка</span>

              <span class="type-duration" v-tooltip="{
                content: freeTooltip,
                placement: 'top',
                html: true,
                theme: 'custom-theme',
                showTriggers: ['click', 'touch', 'hover']
              }">
                  Бесплатно

                  <i class="material-icons">help</i>
              </span>
            </div>

            <div class="price-container">
              <span class="sum">75 000 ₽</span>
              <span class="type">подписка</span>

              <span class="type-duration" v-tooltip="{
                content: businessTooltip,
                placement: 'top',
                html: true,
                theme: 'custom-theme',
                showTriggers: ['click', 'touch', 'hover']
                }">
                  Бизнес

                  <i class="material-icons">help</i>
              </span>
            </div>

            <div class="price-container year">
              <span class="sum">120 000 ₽</span>
              <span class="type">подписка</span>

              <span class="type-duration" v-tooltip="{
                content: premiumTooltip,
                placement: 'top',
                html: true,
                theme: 'custom-theme',
                showTriggers: ['click', 'touch', 'hover']
              }">
                  Премиум

                  <i class="material-icons">help</i>
              </span>
            </div>
          </div>

          <div class="payments-container">
            <span v-tooltip="{
                content: paymentsMethods,
                placement: 'top',
                html: true,
                theme: 'custom-theme',
                showTriggers: ['click', 'touch', 'hover']
              }">
                  Способы оплаты

                  <i class="material-icons">help</i>
              </span>

            <span v-tooltip="{
                content: conditionsRefusal,
                placement: 'top',
                html: true,
                theme: 'custom-theme',
                showTriggers: ['click', 'touch', 'hover']
              }">
                  Условия отказа

                  <i class="material-icons">help</i>
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper contact-form" v-animateonscroll="getScrollAnimate">
    <div class="section business contact-form">
      <div class="column">
        <div class="center">
          <h2 v-if="showDefault" class="common-form">Появились вопросы?</h2>

          <button v-if="showDefault" @click="openForm" class="common-form">
            Связаться с нами
          </button>

          <div class="callback-form" v-if="showCallbackForm">
            <span>Заполните форму, и мы с Вами свяжемся</span>

            <input class="number"
                   type="text"
                   v-model="name"
                   placeholder="Ваше имя"
            />

            <input class="number"
                   type="text"
                   v-model="organization"
                   placeholder="Организация"
            />

            <input class="number"
                   type="tel"
                   v-maska="'+7 (###) ###-##-##'"
                   v-model="phone_number"
                   placeholder="+7 (000) 000-00-00"
            />

            <label for="iAgree">
              <input class="checkbox"
                     type="checkbox"
                     v-model="iAgree"
                     id="iAgree"
              />
              <span>Я согласен с <a href="https://api.obrashaysya.ru/defaults/docs/privacy_policy.html" target="_blank">политикой обработки персональных данных</a>.</span>
            </label>

            <button @click="send" :disabled="iAgree && (phone_number === '' || phone_number === null) || (name === '' || name === null)">
              Отправить
            </button>
          </div>

          <h2 v-if="callbackSuccess" class="finish-form">Спасибо за отклик!</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>